<template>
	<div class="right-main">
		<div class="group">
			<div class="green-title-box"><div class="green-title">账号绑定</div></div>
			<div class="row cb bind-item">
				<div class="row cc left">
					<el-image fit="contain" :src="require('@/pc/assets/user/wechat.png')" class="img"></el-image>
					<div class="col desc">
						<div class="title">绑定微信</div>
						<div class="sub-title">
							<span>已绑定微信号</span>
							<span class="green">yuh67656</span>
						</div>
					</div>
				</div>
				<el-popover placement="left" trigger="click">
					<div class="col cc qr-view">
						<el-image fit="contain" :src="require('@/pc/assets/user/wechat.png')" class="img"></el-image>
						<div class="tips">微信扫码绑定微信号</div>
					</div>
					<div slot="reference"><div class="row cc right a">换绑</div></div>
				</el-popover>
			</div>
			<div class="row cb bind-item">
				<div class="row cc left">
					<el-image fit="contain" :src="require('@/pc/assets/user/phone.png')" class="img"></el-image>
					<div class="col desc">
						<div class="title">绑定手机</div>
						<div class="sub-title"><span>当前尚未绑定手机号，为保证账户安全与数据互联，请立即绑定</span></div>
					</div>
				</div>
				<el-popover placement="left" trigger="click">
					<div class="col cc qr-view">
						<el-image fit="contain" :src="require('@/pc/assets/user/wechat.png')" class="img"></el-image>
						<div class="tips">微信扫码绑定手机号</div>
					</div>
					<div slot="reference"><div class="row cc right a">换绑</div></div>
				</el-popover>
			</div>
		</div>
		<div class="group">
			<div class="green-title-box"><div class="green-title">新消息通知</div></div>
			<div class="row cb notice-item">
				<div class="col ">
					<div class="title">消息通知</div>
					<div class="sub-title"><span>其他用户的评论、点赞、回复、回答等消息的通知</span></div>
				</div>
				<el-switch v-model="notice1" active-color="#03D3BC" inactive-color="#999999"></el-switch>
			</div>
			<div class="row cb notice-item">
				<div class="col ">
					<div class="title">系统通知</div>
					<div class="sub-title"><span>用户关注，积分、余额变动系统消息的通知</span></div>
				</div>
				<el-switch v-model="notice2" active-color="#03D3BC" inactive-color="#999999"></el-switch>
			</div>
			<div class="row cb notice-item">
				<div class="col ">
					<div class="title">待办通知</div>
					<div class="sub-title"><span>问题待处理、答案待采纳、待办事项的通知</span></div>
				</div>
				<el-switch v-model="notice3" active-color="#03D3BC" inactive-color="#999999"></el-switch>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			notice1: true,
			notice2: false,
			notice3: true
		};
	}
};
</script>

<style scoped lang="less">
@import './edit.less';
</style>
